import request from '@/utils/request'

// 登录方法
export function fileUpload(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data: data,
  })
}
